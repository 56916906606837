footer {
    background: var(--color-primary);
    margin-top: 7rem;
    padding-top: 7rem;
    font-size: 0.9rem;
    color: var(--color-gray-100);
}

.footer__container {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;
}

.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer__container article .footer__socials {
    margin-left: 0.9rem;
}

.footer__container article .logo {
    margin-top: -1.5rem;
}

.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

.footer__copyright {
    color: var(--color-gray-100);
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid var(--color-primary-variant);
    margin-top: 5rem;
}

.footer__menu a:hover {
    color: var(--color-secondary);
}


/* MEDIA QUERIES (medium screens) */

@media screen and (max-width: 1024px) {
    .footer__container {
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }
}


/* MEDIA QUERIES (small screens) */

@media screen and (max-width: 600px) {
    footer {
        margin-top: 7rem;
    }

    .footer__container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .footer__container article {
        align-items: center;
    }

    .footer__container article p {
        text-align: center;
    }

    .footer__container article .footer__socials {
        margin-left: 0.3rem;
    }
}