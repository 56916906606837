.faqs__wrapper {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 5rem;
    align-items: flex-start;
}

.faq {
    background: var(--color-gray-500);
    padding: 2rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: var(--transition);
}

.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq:hover {
    background: var(--color-secondary);
    color: var(--color-gray-600);
}

.faq:hover h4 {
    color: var(--color-gray-600);
}

.faq__icon {
    background: transparent;
    color: var(--color-gray-100);
    font-size: 1.5rem;
}

.faq:hover button {
    color: var(--color-gray-600);
}

.faq p {
    margin-top: 1.5rem;
}

.faq__container-btn {
    margin-top: 5rem;
    width: 100%;
    display: flex;
}

/* MEDIA QUERIES (medium screens) */

@media screen and (max-width: 1024px) {
    .faqs__wrapper {
        grid-template-columns: 1fr;
    }
}



/* MEDIA QUERIES (small screens) */

@media screen and (max-width: 600px) {
    .faqs__wrapper {
        gap: 1rem;
        margin-top: 3rem;
    }
}