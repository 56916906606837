.main__header {
    width: 100vw;
    height: calc(100vh - 3rem);
    display: grid;
    place-items: center;
    margin-top: 3rem;
}

.main__header-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
}

.main__header-left {
    margin-top: -3rem;
}

.main__header-left h4 {
    margin-bottom: 1rem;
    color: var(--color-secondary);
    font-weight: 400;
}

.main__header-left p {
    margin: 1rem 0 3.5rem;
    font-size: 1.1rem;
}

.main__header-right {
    display: grid;
    place-items: center;
    position: relative;
}

.main__header-image {
    width: 30rem;
    height: 30rem;
}

/* ================ Banner ================ */
.home__banner {
    width: 100%;
    margin-top: -2rem;
}

/* ================ COURSES ================ */

.courses {
    margin-top: 4rem;
}

.courses__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin-top: 4rem;
}

.courses__course a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
}

.courses__course:hover a {
    background: var(--color-secondary);
    color: var(-color--gray-600);
}


/* ================ VALUES ================ */

.values__container {
    display: grid;
    grid-template-columns: 38% 50%;
    gap: 12%;
}

.values__right>p {
    margin: 1.5rem 0 5rem;
}

.values__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.4rem 2.4rem;
}

.card.values__value {
    /* height: 18rem; */
    padding-top: 3.5rem;
    text-align: left;
    position: relative;
}

.card.values__value span {
    position: absolute;
    top: -1.5rem;
}


/* MEDIA QUERIES (medium screens) */

@media screen and (max-width: 1024px) {

    /* MAIN HEADER */
    .main__header {
        height: fit-content;
        padding: 12rem 0;
    }

    .main__header-container {
        gap: 0;
    }

    .main__header-image {
        width: 16rem;
        height: 16rem;
    }

    /* ================ Banner ================ */
    .home__banner {
        margin-top: -10rem;
        margin-bottom: 5rem;
    }

    /* COURSES */
    .courses__wrapper {
        grid-template-columns: 1fr 1fr;
    }

    /* VALUES */
    .values__container {
        grid-template-columns: 1fr;
        justify-content: center;
        gap: 4rem;
    }

    .values__wrapper {
        gap: 4rem 3rem;
    }

    .values__image {
        display: none;
    }
}


/* MEDIA QUERIES (small screens) */

@media screen and (max-width: 600px) {

    /* MAIN HEADER */
    .main__header {
        margin-top: 0;
        padding: 12rem 0 0;
        height: 100vh;
    }

    .main__header-container {
        grid-template-columns: 1fr;
    }

    .main__header-image,
    .main__header-right {
        display: none;
    }

    /* ================ Banner ================ */
    .home__banner {
        margin-top: -18rem;
        margin-bottom: 0;
    }

    /* COURSES */
    .courses__wrapper {
        grid-template-columns: 1fr;
        gap: 2rem;
        margin-top: 3rem;
    }

    .courses__course {
        width: 84%;
        margin-inline: auto;
    }

    /* VALUES */
    .values__wrapper {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .card.values__value {
        width: 84%;
        margin-inline: auto;
    }
}