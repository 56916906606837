.socials {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 1.4rem;
    /* margin-left: 0.9rem; */
}

.socials a svg {
    color: var(--color-gray-100);
    transition: var(--transition);
}

.socials a:hover svg {
    color: var(--color-secondary);
}