.course__details-container {
    display: grid;
    padding-right: 9rem;
    padding-left: 9rem;
    padding-top: 4rem;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
/* Style the headings */
.course__details-container h2, .course__details-container h3 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-weight: bold;
}

/* Style the paragraph text */
.course__details-container p {
    margin-top: 0;
    margin-bottom: 1.5em;
}

/* Style the list items */
.course__details-container ul li span {
    margin-left: 1rem;
}

.course__details-checkbox {
    color: var(--color-secondary);
}

.course__details-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;
}