.contact__container {
    display: grid;
    place-items: center;
}

.contact__wrapper {
    width: fit-content;
    display: flex;
    gap: 2rem;
    position: relative;
}

.contact__wrapper a {
    background: var(--color-primary);
    font-size: 2rem;
    border: 2px solid transparent;
    transition: var(--transition);
    display: inline-flex;
    vertical-align: middle;
    padding: 2rem;
    border-radius: 1.5rem;
}

.contact__wrapper a:hover {
    background: transparent;
    color: var(--color-secondary);
    border-color: var(--color-secondary);
}


/* Separator */

.line {
    width: 100%;
    height: 2px;
    background-color: var(--color-gray-400);
    margin-top: 8rem;
}


/* Contact form */

.contact__form-container {
    position: relative;
    width: 100%;
    background-color: transparent;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    margin-top: 6rem;
    align-items: center;
    justify-content: center;
}

.contact__form-container .form {
    width: 100%;
    max-width: 820px;
    background-color: var(--color-gray-500);
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.contact__form {
    background-color: var(--color-primary);
    position: relative;
}

.contact__form::before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: var(--color-primary);
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
}

.contact__form form {
    padding: 2.3rem 2.2rem;
    overflow: hidden;
    position: relative;
}

.contact__form form .title {
    color: var(--color-secondary);
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
}

.contact__form form .submit__btn {
    font-family: "Saira", sans-serif;
    font-weight: 500;
    padding: 0.6rem 1.3rem;
    background-color: var(--color-secondary);
    border: 2px solid var(--color-secondary);
    font-size: 1.1rem;
    color: var(--color-primary);
    line-height: 1;
    border-radius: 25px;
    width: 100%;
    outline: none;
    cursor: pointer;
    transition: var(--transition);
    margin: 0;
}

.contact__form form .submit__btn:hover {
    background-color: transparent;
    color: var(--color-gray-100);
}

.input__container {
    position: relative;
    margin: 1rem 0;
}

.input__container label {
    color: var(--color-gray-200);
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 900;
    transition: var(--transition);
}

.input__container .input {
    width: 100%;
    outline: none;
    border: 2px solid var(--color-gray-200);
    background: none;
    padding: 0.6rem 1.2rem;
    color: var(--color-gray-100);
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 25px;
    transition: var(--transition);
}

.input__container textarea.input {
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    min-width: 100%;
    border-radius: 22px;
    resize: none;
    overflow-y: auto;
}

.input__container.textarea label {
    top: 1rem;
    transform: translateY(0);
}

.input__container span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
}

.input__container span::before,
.input__container span::after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: var(--transition);
    height: 5px;
    background-color: var(--color-primary);
    top: 50%;
    transform: translateY(-50%);
}

.input__container span::before {
    left: 50%;
}

.input__container span::after {
    right: 50%;
}

.input__container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
}

.input__container.focus span::before,
.input__container.focus span::after {
    width: 50%;
    opacity: 1;
}

/* Contact Info */
.contact__info {
    padding: 2.3rem 2.2rem;
    position: relative;
}

.contact__info .title {
    color: var(--color-gray-100);
    margin-bottom: 4rem;
}

.contact__info .text {
    color: var(--color-gray-300);
    margin: 1.5rem 0 2rem 0;
}

.contact__info .info .information {
    display: flex;
    color: var(--color-gray-200);
    margin: 0.7rem 0;
    align-items: center;
    font-size: 0.95rem;
    margin-top: 4rem;
}

.contact__info .info .information p {
    margin-left: 0.7rem;
}

.contact__info .contact__socials {
    margin-top: 5rem;
}

/* Message Delivered */
.contact__form-container .message__delivered-text {
    display: flex;
    margin: 0.7rem 0;
    align-items: center;
    gap: 1rem;
    color: var(--color-secondary);
}

/* .con */

/* MEDIA QUERIES (medium screens) */

@media screen and (max-width: 850px) {
    .contact__form-container .form {
        grid-template-columns: 1fr;
    }
}

/* MEDIA QUERIES (small screens) */

@media screen and (max-width: 600px) {
    .contact__wrapper {
        gap: 1.5rem;
    }

    .contact__wrapper a {
        padding: 1rem;
        font-size: 1.2rem;
        border-radius: 1rem;
    }
}