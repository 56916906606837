.course__list-container {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin-left: 9rem;
    /* min-height: 100vh; */
}

.course__card {
    margin-top: 8rem;
    position: relative;
    width: 500px;
    height: 275px;
    background: var(--color-gray-500);
    border-radius: 20px;
    box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
    transition: var(--transition);
}

.course__card:hover {
    height: 450px;
    background: none;
    border: 2px solid var(--color-secondary);
}

.course__card-imgBx {
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translate(-50%);
    width: 300px;
    height: 200px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
    overflow: hidden;
    transition: var(--transition);
}

.course__card-imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.course__card:hover .course__card-imgBx {
    width: 399px;
    height: 233px;
}

.course__card .course__card-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
}

.course__card .course__card-content .course__card-details {
    padding: 40px;
    text-align: center;
    width: 100%;
    transition: var(--transition);
    transform: translateY(150px);
}

.course__card:hover .course__card-content .course__card-details {
    transform: translateY(0);
}

.course__card .course__card-content .course__card-details h2 {
    font-size: 1.25em;
    font-weight: 600;
    color: var(--color-gray-100);
    line-height: 1.2em;
}

.course__card .course__card-content .course__card-details h2 span {
    font-size: 0.75em;
    font-weight: 500;
    opacity: 0.5;
}

.course__card .course__card-content .course__card-details .course__card-data {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.course__card .course__card-content .course__card-details .course__card-data h3 {
    font-size: 1em;
    color: var(--color-gray-100);
    line-height: 1.2em;
    font-weight: 600;
}

.course__card .course__card-content .course__card-details .course__card-data h3 span {
    font-size: 0.85em;
    font-weight: 400;
    opacity: 0.5;
}

.course__card-btn {
    justify-content: center;
}

.course__card-btn button {
    padding: 10px 30px;
    border: none;
    outline: none;
    border-radius: 20px;
    height: 50px;
    width: 150px;
    font-size: 1em;
    font-weight: 500;
    background: var(--color-secondary);
    color: var(--color-primary);
    cursor: pointer;
}

.course__card-btn button:hover {
    background: var(--color-primary);
    color: var(--color-gray-100);
    border: 2px solid var(--color-secondary);
}


/* MEDIA QUERIES (medium screens) */

@media screen and (max-width: 1024px) {
    .course__list-container {
        grid-template-columns: 1fr;
        justify-content: center;
        place-items: center;
        margin-left: 0;
    }

    .course__card {
        height: 420px;
    }

    .course__card:hover {
        height: 420px;
    }

    .course__card .course__card-content .course__card-details {
        transform: translateY(20px);
    }

    .course__card:hover .course__card-content .course__card-details {
        transform: translateY(20px);
    }

    .course__card-imgBx {
        width: 399px;
        height: 233px;
    }
}

/* MEDIA QUERIES (small screens) */

@media screen and (max-width: 600px) {
    .course__list-container {
        grid-template-columns: 1fr;
        /* margin-left: 3rem; */
        justify-content: center;
        place-items: center;
        margin-left: 0;
    }

    .course__card {
        width: 300px;
        height: 400px;
    }

    .course__card:hover {
        width: 300px;
        height: 400px;
    }

    .course__card-imgBx {
        width: 200px;
        height: 120px;
    }

    .course__card:hover .course__card-imgBx {
        width: 200px;
        height: 120px;
    }

    .course__card .course__card-content .course__card-details .course__card-data {
        gap: 1.5rem;
    }

    .course__card .course__card-content .course__card-details .course__card-data h3 {
        font-size: 0.85em;
        line-height: 1.3em;
        font-weight: 400;
    }
}