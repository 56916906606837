.teachers__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
}

.teachers__container .container .avatar img {
    border-radius: 50%;
    width: 140px;
    height: 140px;
    margin-top: 5%;
    object-fit: cover;
}

.teachers__container .container .avatar {
    margin-top: 5%;
    display: inline-block;
}

.teachers__container .container {
    width: 55%;
    background-color: var(--color-primary);
    height: 65vh;
    text-align: center;
    border-radius: 5px;
}

.teachers__container .container .content {
    padding: 10px;
}

.teachers__container .container .content h1 {
    color: var(--color-secondary);
}

.teachers__container .container .content h3, .teachers__container .container .content p {
    font-weight: lighter;
    letter-spacing: 1px;
    width: 70%;
    margin: 10px auto;
}

.teachers__container .container .content h3 {
    font-weight: 400;
}

.teachers__container .container .social a {
    padding: 0 10px;
    font-size: 30px;
    transition: var(--transition);
    cursor: pointer;
}

.teachers__container .container .social a:hover {
    color: var(--color-secondary);
}

.teachers__container .container .social {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 5rem;
}

/* Media queries for responsiveness */

@media screen and (max-width: 768px) {
    .teachers__container .container {
        width: 90%;
        height: auto;
        margin: 0 auto;
    }

    .teachers__container .container .avatar img {
        width: 100px;
        height: 100px;
    }

    .teachers__container .container .content h2, .teachers__container .container .content p {
        width: 90%;
    }

    .teachers__container .container .social a {
        font-size: 24px;
    }

    .teachers__container .container .content {
        padding: 10px;
    }
}

@media screen and (max-width: 480px) {
    .teachers__container .container {
        width: 90%;
        height: auto;
        margin: 0 auto;
    }

    .teachers__container .container .avatar img {
        width: 80px;
        height: 80px;
    }

    .teachers__container .container .content h2, .teachers__container .container .content p {
        width: 90%;
    }

    .teachers__container .container .social a {
        font-size: 18px;
    }
}