.info__banner {
    height: 7rem;
    background-color: var(--color-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.info__banner .info__banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info__banner .info__banner-content a h3,
.info__banner .info__banner-content h3 {
    display: inline-block;
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
}

.info__banner .info__banner-content a h3 {
    color: var(--color-gray-400);
}

.info__banner .info__banner-content h3 {
    color: var(--color-primary);
}

/* MEDIA QUERIES (small screens) */

@media screen and (max-width: 600px) {

    .info__banner .info__banner-content a h3,
    .info__banner .info__banner-content h3 {
        font-size: 1.1em;
    }
}