.scrollup {
    text-align: center;
    bottom: 40px;
    cursor: pointer;
    position: fixed;
    right: 20px;
    z-index: 999;
    border-radius: 50px 50px 4px 4px;
}
.scrollup i {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-secondary);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}
.scrollup i:hover {
    opacity: 0.8;
}
.scrollup.orange-color i {
    background: #ff5421;
}
.scrollup.purple-color i {
    background: #787cf2;
}
.scrollup.yellow-color i {
    background: #f4bf00;
}
.scrollup.green-color i {
    background: #0c8b51;
}
